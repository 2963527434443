import { Loader, Text } from "@mantine/core";
import { T } from "@tolgee/react";

export default function LoadingPage() {
  return (
    <div className="safe-top flex h-full flex-col items-center justify-center p-4">
      <Loader className="mb-4" size="xl" />
      <Text>
        <T
          keyName="components.LoadingPage.title"
          defaultValue="Take deep breaths"
        />
      </Text>
    </div>
  );
}
